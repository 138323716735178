import React, { useContext } from "react"
import Layout from "@components/Layout/Layout"
import Container from "@components/Container/Container.jsx"
import MainWrap from "@components/Wrap/MainWrap.jsx"
import { Typography } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Markdown from "markdown-to-jsx"
import ButtonBack from "@components/Buttons/ButtonBack.jsx"
import { makeComponentStyles } from "@components/mixins.js"
import { IntlContextConsumer, navigate, useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "@src/heplers.js"
import Seo from "../../components/seo"

const styles = ({ palette }) => ({
  datetime: {
    color: palette.primary.main
  }
})

function Show({ pageContext }) {
  const intl = useIntl();
  const payload = getStrapiContentByLang(pageContext.payload, intl.locale)
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up("md"))
  const {
    datetime
  } = makeComponentStyles(styles);

  const { AdsSEO } = payload;

  return (
    <>
      <Seo title={AdsSEO?.TitleSEO}
           description={AdsSEO?.DescriptionSEO}
           keywords={AdsSEO?.KeywordsSEO?.split(',')}
      />
      <Layout>
        <Container>
          <MainWrap>
            <MainWrap.Header>
              <ButtonBack click={() => navigate('/ads')} />
              <Typography component={"h1"} variant={"h2"}>{payload.Title}</Typography>
              <Typography component={"caption"} className={datetime}>{payload.published_at}</Typography>
            </MainWrap.Header>
            <MainWrap.Body>
              <Typography style={{ fontSize: isDesktop ? "18px" : "14px" }}>
                <Markdown>{payload.Content}</Markdown>
              </Typography>
            </MainWrap.Body>
          </MainWrap>
        </Container>
      </Layout>
    </>
  )
}

export default Show
