import React from 'react';
import ButtonLink from "./ButtonLink.jsx"
import { makeComponentStyles } from "@components/mixins.js"
import ArrowLeft from "@static/images/ArrowLeft.svg"
import { useIntl } from "gatsby-plugin-react-intl";

const styles = () => ({
  btn_back: {
    display: "flex",
    padding: 10,
    paddingLeft: 0,
    alignItems: "center",
  },
  icon: {
    marginTop: -1,
    marginRight: "10px"
  }
})

function ButtonBack({ click }) {
  const intl = useIntl();
  const { btn_back, icon } = makeComponentStyles(styles);
  const handleClick = (e) => click(e);
  return (
    <ButtonLink onClick={handleClick} className={btn_back}>
      <img src={ArrowLeft} alt="arrow-left" className={icon} /> {intl.formatMessage({ id: "back" })}
    </ButtonLink>
  )
}

export default ButtonBack;
